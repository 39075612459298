/* -----Power Reviews------ */

#pr-review-display {
  .p-w-r {
    .pr-snippet-reco-to-friend {
      .pr-reco-to-friend-message {
        font-size: 15px;
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          width: 41%;
          &:before {
            content: 'Filtrar por: ';
          }
        }
        .pr-rd-main-header-search-sort {
          width: 59%;
        }
        .pr-rd-review-header-sorts {
          width: 50%;
          .pr-rd-sort-group {
            float: right;
            padding-left: 1px;
          }
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 100% 5px
            transparent;
          width: 66%;
        }
        .pr-rd-main-header-search {
          width: 47%;
          float: right;
          padding-left: 0;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: 'Ordenar por: ';
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'SIM';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'NÃO';
              }
            }
          }
        }
      }
    }
  }
}
