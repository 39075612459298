#top_wrapper {
  #navigation {
    .outer-wrap {
      max-width: 1250px !important;
    }
  }
}

.elc-product-full {
  .elc-overlay-sidebar {
    .elc-service-view-renderer {
      flex-grow: 1;
    }
  }
}

.elc-size-picker-wrapper,
.elc-product-shade-picker-dropdown {
  .elc-dropdown {
    &-readonly-input {
      width: 100%;
    }
    &-arrow {
      z-index: -1;
    }
  }
}

.footer-links__link {
  span {
    display: none;
  }
}
