.appt-book {
  .service-select {
    .service {
      min-height: 400px;
      .service-details {
        min-height: 130px;
        h3.service-title {
          font-size: 28px;
        }
      }
      .selection-bar {
        max-width: 250px;
        .selection-selector {
          width: 58%;
        }
        .selection-data {
          width: 42%;
        }
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .service {
        .service-head {
          font-size: 28px;
          margin-bottom: 20px;
        }
        .selection-bar {
          max-width: 250px;
          .selection-data {
            width: 42%;
          }
          .selection-selector {
            width: 58%;
          }
        }
      }
    }
  }
  .appt-book-location-selector {
    .location-select,
    .location-submit,
    .state-select {
      width: 377px;
    }
  }
  .book-now-bar {
    max-width: 320px;
    .minutes {
      width: 40%;
    }
    .button {
      width: 60%;
    }
  }
  .appt-book-page-header {
    min-height: 300px;
  }
  #booking-step3 {
    .book-appt-container {
      .mobile-phone-fieldset {
        float: right;
        padding: 0;
        .appt-book-mobile-prefix {
          width: 20%;
          display: inline-block;
        }
        .appt-book-mobile {
          width: 80%;
        }
      }
      .registration__terms.error {
        color: $color-notice-pink;
        a {
          color: $color-notice-pink;
        }
      }
    }
    input[type='checkbox'] {
      margin-right: 8px;
    }
  }
  .appointment-container {
    .appt-date {
      p {
        display: inline-block;
      }
    }
  }
  .appt-book-section {
    .appt-book-section-content {
      .confirmation-container {
        span {
          font-size: 20px;
        }
      }
    }
  }
}

#appointments {
  .my-appointments-overlay {
    .overlay-content-container {
      .overlay-content.canceled {
        span {
          p {
            display: inline;
          }
        }
      }
    }
  }
}
