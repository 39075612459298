$color-gray: #bbbbbb;

#main.main {
  section.shipping-billing {
    .address-book-info {
      .column {
        margin: 10px 0;
        .address_display {
          line-height: 1.5em;
          .controls {
            .default-address {
              display: block;
            }
          }
        }
      }
    }
    .payment_book_container {
      .payment-info {
        margin: 10px 0;
        > div {
          line-height: 1.5em;
        }
      }
    }
  }
  .registration_content {
    .full-width-wrap {
      fieldset.demographic.fs {
        .form_element.birth_date_container {
          select#form--profile_preferences--field--BIRTH_YEAR {
            display: inline-block;
          }
        }
      }
      fieldset.email_promotions {
        .form_element.pc_email_promo_container {
          label {
            display: inline-block;
          }
        }
        .form_element.sms_promo_container {
          label {
            display: inline-block;
          }
        }
      }
    }
    .first-column-wrap {
      fieldset.profile.fs {
        .form_element.set-email-container-height {
          min-height: 57px;
        }
      }
    }
  }
  .module-spp-detail {
    .page-spp {
      .shades .shade-dd-menu {
        .col1 {
          margin: 0 !important;
          .duo-smoosh {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
  .account-favorites-mylists {
    .lists .list .items-5 {
      margin: 0 16px;
      width: 36%;
    }
  }
  .module-spp-detail,
  .options {
    .spp_product_status {
      .temp_out_of_stock_msg {
        line-height: 21px;
        a.btn-email {
          display: block;
          width: 30%;
          margin: 5px 0;
          clear: left;
        }
      }
    }
  }
  section.recommended-for-you.panel {
    .prod-shelf-row ul.products {
      width: 100%;
    }
  }
  section.best-sellers {
    &.normalizeHeight {
      a.btn-shopnow {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
// updated for Bugzilla Bug-247369
.prod-shelf-row.five-products {
  position: relative;
}
// updated for Bugzilla Bug-266799
.messages.public {
  display: none;
}

.extra {
  .inner {
    .field {
      p {
        margin: 0px;
      }
    }
  }
}

.locations_table_panel {
  #store_results {
    table {
      td.store_name.marker {
        display: table-cell;
      }
      td {
        span.store_name {
          display: block;
        }
      }
    }
  }
}

html.ie8 {
  #search-wrapper {
    #summary {
      .search-again {
        input.search-term {
          border: 1px solid #bdbcbc;
          padding-top: 6px;
        }
        input.search-again {
          cursor: pointer;
          background: url('/sites/clinique/themes/cl_base/template_api/gnav_search_v1/icon-search.png')
            no-repeat scroll 102% center;
        }
      }
    }
  }
}

.section-store-locator {
  .store-locator-body {
    .form_element.search_alternative_text {
      margin: 3px 20px 0;
    }
    .form_element.search_container {
      input.search {
        width: 187px;
        letter-spacing: 0em;
      }
    }
    .form_element.country_container {
      .sbHolder {
        width: 180px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .section-store-locator {
    .store-locator-body {
      .form_element.search_alternative_text {
        margin: 11px 8px 0;
      }
      .form_element.search_container {
        input.search {
          width: 190px;
          letter-spacing: 0em;
          margin-top: 10px;
        }
      }
      .form_element.geo_container,
      .form_element.search_container {
        .button {
          width: 150px;
        }
      }
      .form_element.country_container {
        margin: 10px 8px;
      }
    }
  }
}
/* online consultant chat link - Live Engage Start */
.customer-care-our-online-consultants {
  .cs-formatter-v1 {
    .customer-care-list {
      .customer-care-list-item.item-1 {
        .bottom {
          top: 131px;
        }
      }
    }
  }
}
/* online consultant chat link - Live Engage End */
#sticky-bar {
  #sticky_bar_content {
    .sticky_content {
      .sticky_prod_select {
        padding-right: 160px;
        .sbHolder {
          width: 170px;
          float: left;
        }
      }
      .spp_product_status {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
}

.back-to-top {
  bottom: 2.8em;
}

.gnav-drop-down-v1 {
  .stores-menu-v1 {
    form#store-locator .form-submit {
      font-size: 14px;
      letter-spacing: 0;
    }
  }
  .dd-content.active {
    width: auto;
  }
}

body#index {
  #main.single {
    .product {
      .qty {
        width: 23%;
      }
      .desc {
        width: 39%;
      }
      .thumb {
        width: 38%;
      }
    }
    #new-account {
      .checkbox {
        input[type='checkbox'] {
          width: 5%;
        }
      }
    }
    #order-summary-panel {
      .order-summary__content {
        p.viewcart-page-shipmethodtext {
          display: none;
        }
      }
    }
  }
}

.past-purchases {
  .order.accordion-header {
    display: inline-block;
    .view-details {
      float: right;
    }
  }
  .shipments-list {
    .refill {
      display: none;
    }
  }
}

.spp_reviews {
  #BVRRSecondarySummaryContainer {
    margin-bottom: 40px;
    .BVRRSecondaryRatingSummary.BVRRSecondaryRatingSummaryNoReviews {
      #BVRRRatingSummaryNoReviewsWriteImageLinkID {
        top: 52px;
        width: auto;
        a {
          width: auto;
        }
      }
      .BVRRCustomNoReviewsPrefix {
        display: block;
      }
      #BVRRRatingSummaryLinkWriteFirstID {
        padding: 0px;
        float: none;
        a,
        .BVRRRatingSummaryLinkWriteFirstPrefix {
          font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
          letter-spacing: -0.1mm;
          font-weight: normal;
          line-height: normal;
          color: #666;
          font-size: 17px;
        }
      }
    }
  }
}

.device-pc {
  .dd-content {
    &.active {
      //width: 556px;
      #store-locator {
        .form-text {
          padding: 0 0 0 3px;
          width: 248px;
        }
        .form-submit {
          padding-top: 1px;
        }
      }
    }
    .cart-confirm-wrapper {
      .qty-price {
        &.right-copy {
          .strike-thru-price {
            display: inline;
            text-decoration: line-through;
          }
          .highlighted-price {
            display: inherit;
            color: #ff195e;
          }
        }
      }
      .prod {
        padding: 0 10px 0 0;
      }
      .hcenter {
        width: 315px;
      }
      .qty {
        margin-right: 15px;
      }
      .items {
        width: 45%;
      }
      .subtotal {
        width: 55%;
        padding-right: 10px;
      }
    }
  }
  .content {
    .customer-care-list {
      div.customer-care-list-item {
        float: none;
        margin: 0 auto;
        padding: 10px 2.5%;
      }
      .customer-care-list-item.item-2 {
        display: none;
      }
    }
    .cs-page-section-formatter-v1 {
      .cs-page-section {
        .cs-page-section-row-v1 {
          .section-details {
            .section-title {
              font-size: 32px;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .diagnostics-merch-window.has-image {
    background-position: 468px;
  }
  .contact_us_wrap {
    #contact_us {
      ul.category {
        min-height: 197px;
        li {
          min-height: 168px;
          width: 173px;
          p.copy {
            font-size: 23px;
            line-height: 20px;
          }
        }
      }
      .question_header {
        .caret {
          width: 170px;
        }
      }
      .question_header.beauty_product {
        padding-left: 184px;
      }
      .question_header.website_tech {
        padding-left: 370px;
      }
      .question_header.store_service {
        padding-left: 500px;
        .caret {
          width: 280px;
        }
      }
    }
  }
}

#address_form_container #address-form-popover {
  fieldset.fs {
    div.form_element {
      width: 46%;
      height: auto;
    }
    .default_bill_container,
    .default_ship_container {
      float: left;
    }
  }
}

.account-favorites-mylists .lists {
  .list.create-list {
    .create-wishlist-form .list-icon label {
      background: transparent url(/media/images/css/sprite-favorites-list-icons.png) 0 0 no-repeat;
      cursor: pointer;
      float: left;
      height: 28px;
      margin: 0 25px 0 0;
      overflow: hidden;
      text-indent: -9999px;
    }
    .button.create-list {
      margin: 20px auto 0;
    }
  }
}

.account-favorites-mylists .lists {
  .list.create-list {
    .create-wishlist-form .list-icon label:first-child {
      background: none;
      cursor: pointer;
      float: none;
      height: auto;
      @include swap_direction(margin, 0 0 4px 0);
      text-indent: 0px;
      overflow: visible;
    }
  }
}

.device-pc.pc_spp_view {
  .full_sign_in {
    .row {
      .form-text {
        height: 35px;
        line-height: 35px;
      }
    }
  }
}

.lpPoweredBy {
  display: none;
}

.content {
  .header-nav-formatter-v1 {
    .shortcuts {
      .header-nav-row-v1 {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
        .lpStaticButtonTR {
          a {
            padding: 0 0;
          }
        }
      }
      li.header-nav-row-v1.live-chat a {
        border: none !important;
        background: none !important;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      li.header-nav-row-v1.live-chat a img {
        border: none !important;
        background: none !important;
        margin-left: 5px;
        padding: 0;
        width: auto;
        height: 17px;
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-view-options {
    h2 {
      width: 250px;
    }
  }
  .mpp-grid {
    li.mpp-box {
      .mpp-product-hover-view {
        .btn-quickview {
          width: 50%;
          left: 0;
          right: 0;
          margin: auto;
          top: 50%;
          margin-top: -23px;
        }
      }
    }
  }
}

#search-wrapper.products .results .result .badge {
  .badge-inner.badge_data_1,
  .badge-inner.badge_data_30 {
    display: none;
  }
  .badge-inner.badge_data_1.new,
  .badge-inner.badge_data_30.bestseller {
    display: inline-block;
  }
}

.address_lookup_wrapper {
  border: 1px solid $color-gray;
  clear: both;
  padding: 10px;
  width: 100%;
  height: 300px;
  overflow: auto;
  li {
    padding-bottom: 1em;
  }
}

.profile-form-container {
  .top_lable_form {
    text-transform: capitalize;
    display: inline-block;
    margin: 0 20px 15px 5px;
    width: 100%;
  }
}

.profile-form-container {
  .top_label_form {
    margin-bottom: 10px;
  }
}

.profile-form-container {
  .static-id-fields {
    float: left;
    margin: 7px 0 0 0;
    min-width: 215px;
    width: auto;
    min-height: 35px;
  }
}

.passwordreset {
  padding-top: 120px;
}

#shipping-panel {
  .gift-options-display {
    display: block;
  }
  .shipping-edit-content {
    .address-form {
      .form-item.checkbox {
        label {
          display: inline;
        }
      }
    }
  }
}

.shipping-edit-content {
  section.gift-options__content {
    display: block;
  }
}

.mobile-signup {
  display: none;
}

section.viewcart-cpf-field {
  padding: 20px 0 5px 0;
  .postcode-lookup {
    padding-bottom: 10px;
    .postcode-zipfields-postcode-message {
      float: left;
      padding-left: 140px;
    }
    .postcode-zipfields {
      float: left;
      padding-left: 10px;
      input[name='ZIP3'] {
        width: 42px !important;
      }
    }
  }
  .lookup_results_container {
    .address_lookup_wrapper {
      float: right;
    }
  }
}

.shipping_address_container {
  float: left;
  width: 100%;
}

.state-cointainer {
  float: left;
}

section.section-sms-promotions {
  display: none;
}

#main.single {
  .checkout__content {
    .shipping-edit-content {
      .shipping-edit-address-content__content {
        .select-address {
          .new-address {
            .address-form {
              .postal-code {
                position: relative;
                .address_lookup_submit {
                  position: absolute;
                  left: 390px;
                  top: 5px;
                }
              }
            }
          }
        }
      }
    }
    #registration-panel {
      .checkout__panel-content {
        .how-will-we-use {
          border: 0px;
        }
      }
    }
  }
}

html.ie8 .single#main {
  .checkout__content {
    #shipping-panel {
      .shipping-edit-content {
        .shipping-edit-address-content__content {
          .select-address {
            .address-form {
              .city-state-zip {
                select {
                  max-width: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.formattedInstallmentPrice {
  color: #db1f8e;
}

.viewcart {
  .checkout__sidebar {
    .tab-switcher {
      .tab-content {
        .samples-pane {
          .samples {
            border-top: 1px solid #ccc;
          }
        }
      }
    }
  }
}

body {
  .single#main {
    section#samples-panel {
      ul.product-list {
        li.product {
          height: 150px;
        }
      }
    }
  }
}

.overlay-container {
  .create-wishlist-form {
    a.create-list {
      margin-top: 10px;
    }
    .list-icon {
      label {
        background: transparent url(/media/images/css/sprite-favorites-list-icons.png) 0 0 no-repeat;
        cursor: pointer;
        float: left;
        height: 28px;
        @include swap_direction(margin, 0 25px 0 0);
        overflow: hidden;
        text-indent: -9999px;
      }
      label:first-child {
        background: none;
        float: none;
        height: 20px;
        margin: 0px;
        text-indent: 0px;
        overflow: visible;
      }
    }
  }
}

.mm_3col_1tout_v1 {
  .explore {
    width: 100%;
    bottom: 14px;
  }
}

.checkout__content {
  .payment-edit-content {
    .payment-type {
      .payment-container {
        .payment-form {
          .payment-form__pp_cash {
            .cash_type_field {
              display: none;
            }
          }
        }
      }
    }
  }
}

.wrapper {
  #top_wrapper {
    #navigation {
      .menu-item-block {
        .mm_4col_v1 {
          .col {
            width: 24%;
            .col-info {
              .heading {
                font-size: 23px;
              }
            }
          }
        }
      }
    }
  }
  #search-wrapper {
    #results-container {
      clear: both;
      #product-results {
        a.product-spp-link {
          width: 181px;
          height: 209px;
        }
      }
    }
  }
}

#profile_preferences {
  input.error {
    border: 1px solid #ef6ea8 !important;
  }
  select.error {
    border: 1px solid #ef6ea8 !important;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  #contact-form-wrap.personal-info-form-container {
    .first-column-wrap {
      width: 36%;
    }
    .second-column-wrap {
      width: 64%;
      .form_element.button_container {
        input {
          font-size: 14px;
          line-height: 37px;
        }
      }
    }
  }
}

#ff {
  #refinements {
    dl.dimension-8164 {
      .finish_help_show {
        .finish_help_text {
          width: 200px;
          padding: 22px 22px 4px 42px;
        }
        .finish_help_btm {
          background: url(/media/images/foundation_finder/finish_help_systext_btm.png) no-repeat 5px
            top !important;
        }
      }
    }
  }
}

.customer-care-returns-exchanges {
  .basic-responsive-v1 {
    p a {
      font-size: 13px;
    }
  }
}

.spp_reviews {
  .BVRRRatingSummary {
    .BVRRQuickTakeSummary {
      .BVRROverallRatingContainer {
        width: 175px;
      }
      .BVRRRatingSummaryLinkWrite {
        width: 229px;
        right: 246px;
        &::after {
          height: 84px;
          right: -7px;
        }
      }
    }
    .BVDI_QT {
      .BVDI_QTSummaryBox {
        .BVRRBuyAgainContainer {
          width: 240px;
        }
      }
      .BVDI_QTToggles {
        left: 420px;
        width: 100px;
      }
    }
    .BVRRSortAndSearch {
      .BVFieldSearch {
        label {
          display: block;
          float: left;
          width: 76px;
          .BVFieldLabel {
            line-height: 20px;
          }
        }
      }
    }
  }
}

.section-matte-makeover {
  .shades {
    .smoosh-wrapper {
      width: 118px;
      height: 121px;
    }
  }
}

.section-product {
  .what-people-are-saying-module-v1 {
    .manual-reviews {
      height: 240px;
    }
  }
}

.section-products {
  .spp_product_status {
    .temp_out_of_stock_msg {
      a.btn-email {
        clear: left;
      }
    }
  }
  .cl-mpp-product-layout-v1 {
    .mpp-view-options {
      margin-bottom: 10px;
    }
  }
}

.section-products {
  #quickshop-wrapper {
    .module-spp-detail {
      .price {
        border: none;
      }
      .shades {
        padding-bottom: 6px;
        border-bottom: 1px solid #dbdbdb;
      }
    }
  }
}

.three-step-sticky-header {
  .title {
    line-height: 20px;
  }
}

#signin {
  .signin_container {
    #signin-block form {
      .form-item {
        #fedid-fields {
          .required_mark,
          .identification-type {
            float: left;
            display: block;
          }
          .identification-type {
            width: 98%;
          }
        }
        input[type='text'],
        input[type='password'] {
          width: 295px;
        }
      }
      .email_promotions {
        width: 100%;
        float: left;
        input,
        span {
          float: left;
        }
      }
    }
    #signin-block {
      h2.myaccount-hdr + p {
        width: 73%;
      }
    }
  }
}

.account-favorites {
  .no-favorites {
    .no-favorites-text {
      width: 100%;
    }
    .no-favorites-image {
      display: none;
    }
  }
}

.wrapper {
  .top-right {
    .offer-promo-carousel-v1 {
      width: 500px;
      br {
        display: none;
      }
    }
  }
}

.pc_spp_view {
  .price {
    .formatted_price {
      color: #f6416c;
    }
    .formatted_price--non_sale {
      text-decoration: line-through;
      padding-right: 5px;
    }
  }
}

#BVRRDisplayContentHeaderID {
  .BVRRDisplayContentHeaderContent {
    .BVFieldSearch {
      display: none;
    }
  }
}

.popup-offer-v1 {
  h2 {
    margin: 22px 0 20px;
  }
  form .form-submit-wrap {
    input.button[type='submit'] {
      margin-left: -4px;
    }
    .form-text {
      width: 66%;
    }
  }
}

#search-wrapper {
  #results-container {
    #product-results {
      .details {
        .prod_price2--non-sale {
          text-decoration: line-through;
          font-size: 13px;
          line-height: 1.2;
          color: #dbdbdb;
          display: inline;
        }
      }
    }
  }
}

#search-wrapper {
  #results-container {
    #product-results {
      .details {
        .prod_price--sale {
          color: #ff195e;
          font-size: 13px;
        }
      }
    }
  }
}

#main.single #order-summary-panel {
  .order-summary__content {
    p.order-summary__shipping-method {
      float: left;
      width: 20%;
      font-weight: bold;
    }
    .cpf-field {
      float: right;
      width: auto;
      margin-right: 20px;
      section.viewcart-cpf-field {
        padding: 13px 0 5px 0;
      }
      input {
        width: 100%;
        height: 24px;
      }
    }
    #checkout_shipmethod {
      select {
        width: 100%;
        height: 24px;
        padding-left: 0px;
      }
    }
    .label {
      width: 75%;
    }
    .value {
      width: 25%;
    }
  }
}

#viewcart {
  #main.single #order-summary-panel {
    .order-summary__content {
      .order-summary__shipping-label {
        margin-top: 0;
      }
      p.order-summary__shipping-method {
        float: right;
        width: auto;
        margin-right: 15px;
        padding-top: 10px;
      }
      p.index-page-shipmethodtext {
        display: none;
      }
      .cpf-field {
        width: 18%;
      }
    }
  }
}

.most-recent-purchases {
  .prod-shelf-row {
    .info {
      .view-order {
        text-transform: none;
      }
    }
  }
}

.module {
  .pcg-border-formatter.holiday-image-flipover {
    background-image: url('/media/export/cms/imagem-de-fundo.jpg');
  }
}

#main.single {
  .offer-code-panel,
  .sign-in-panel {
    label {
      display: none;
    }
  }
}

// LATAMMAINT-2604 bullet #5 bug fix
.past-purchases .order-info.accordion-content .products .collection .product-info,
.past-purchases .order-info.accordion-content .products .product .product-info {
  width: 20%;
}

.order-timeline {
  display: block !important;
  margin-bottom: 40px;
  ul {
    display: flex;
  }
}

.order-timeline__step {
  text-align: center;
  position: relative;
  pointer-events: none;
  opacity: 0.3;
  flex: 1;
  + .order-timeline__step {
    margin-left: 16px;
    &:before {
      content: '';
      border-top: 1px solid $color-cl-green-2;
      width: 100%;
      position: absolute;
      top: 8px;
      transform: translate(calc(-100% - 8px));
    }
  }
  + .order-timeline__step_large {
    &:before {
      width: calc(100% - 18px);
    }
    + .order-timeline__step {
      &:before {
        width: calc(100% + 20px);
      }
    }
  }
  @media screen and (min-width: 768px) {
    + .order-timeline__step_large {
      &:before {
        width: 100%;
      }
      + .order-timeline__step {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

.order-timeline__step_active {
  opacity: 1;
  pointer-events: auto;
  .order-timeline__step-bullet {
    background-color: $color-cl-green-2;
    border: 0;
  }
}

.order-timeline__step-bullet {
  background-color: $color-white;
  border: 1px solid $color-cl-green-2;
  border-radius: 100%;
  font-size: 14px;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  width: 17px;
  height: 17px;
}

.order-timeline__step_cancel .order-timeline__step-bullet {
  background-color: $color-cl-pink;
}

.order-timeline__step-title {
  font-size: 14px;
  max-width: 91px;
  margin: auto;
  font-weight: normal;
}

.order-timeline__step-subtitle {
  font-family: inherit;
  font-size: 10px;
}

.sitewide-footer-formatter__links {
  .footer_ws-link {
    width: 22px;
    height: 22px;
  }
  .footer_privacy-policy {
    color: #f00;
    display: unset;
  }
}
